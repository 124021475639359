<template>
  <main
    class="color-primary page_bg_grey h-100 min-h-100vh blank-aside-js w-100"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <TourismFilter 
      :hasTypeDeal="false" 
      @on-change="handleSetFilter"
      @handleFilter="handleFilter"
      :pToId="pToId"
    ></TourismFilter>
    <BannerSection location="homepage-top"></BannerSection>
    <section class="tourism-container">
      <div class="d-flex align-items-center justify-content-between flex-wrap mt-10">
        <h5 v-if="filters.to_name">
          {{ filters.to_name }}
        </h5>
        <h5 v-else>
          All flights
        </h5>
        <!-- <div class="d-flex fs14">
          <p class="mx-5">
            {{ $t( "Date range" ) }}: <b>01/04/2021 - 10/04/2021</b>
          </p>
          <p class="mx-5">
            {{ $t( "Duration" ) }}: <b>3 - 5 Nights</b> 
          </p>
          <p class="mx-5">
            {{ $t( "Travelers" ) }}: <b>2 Adults</b> 
          </p>
        </div> -->
        <div class="d-flex">
          <SortTourism @sortDeals="sortDeals" :isTourismDeal="false"></SortTourism>
          <!-- <FilterTourism></FilterTourism> -->
        </div>
      </div>
      <div class="row mt-2" v-if="gsv(sortedTourismDeals, ['items'],  []).length">
        <div class="col-md-3" 
          v-for="(deal, index) in sortedTourismDeals.items"
          :key="index"
          v-if="deal.dealType == 'CHARTER'"
        >
          <FlightTourismItem :deal="deal">
            <!-- <p class="fs14 text-center" slot="title">
              Flights to <b>{{ deal.dealDestination }}</b>
            </p> -->
            <button 
              class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn mt-7" 
              slot="actionPart" 
              @click="openFullDetailModal2(deal)"
            >
              <img src="/assets/img/icons/info-white.svg" alt="" class="">
              {{ $t( "Details" ) }}
            </button>
            <router-link
              slot="routerBlock"
              :to="{
                name: routerName,
                params: { id: deal.dealId, rowId: deal.rowId, destinationId: deal.destination_id, dealType: deal.type },
              }"
              class="primary-btn small-btn white--text fs16 bg-3"
            >
              {{ $t( "Choose" ) }}
            </router-link>
            <!-- <button  class="primary-btn small-btn fs16 bg-3" @click="handleClickDeal(deal)" >
                {{ $t( "Choose" ) }}
            </button> -->
            <!-- <router-link
              :to="{
                name: 'FlightDestinationDates',
              }"
              slot="routerBlock" 
            >
              <button class="primary-btn small-btn fs16 bg-3" @click="handleClickDeal(deal)" >
                {{ $t( "Explore more Dates" ) }}
              </button>
            </router-link> -->
          </FlightTourismItem>
        </div>
      </div>
      <h2 v-else 
        class="text-center mt-10 mb-10"
      >
        <span v-show="!loading">{{ $t('No data') }}</span>
      </h2>
    </section>
    <FullDetailModal2 ref="fullDetailModal2Ref"></FullDetailModal2>
    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import tourismMixin from '@/utility/tourism_mixin'
import BannerSection from "../../../components/BannerSection";
import TourismFilter from "../../../components/tourism/TourismFilter";
import FilterTourism from "../../../components/tourism/FilterTourism";
import SortTourism from "../../../components/tourism/SortTourism";
import FlightTourismItem from "../../../components/tourism/flight/FlightTourismItem";
import FullDetailModal2 from "../../../components/tourism/flight/FullDetailModal2.vue";

export default {
  name: "FlightAll",
  mixins: [tourismMixin],
  components: {
    BannerSection,
    TourismFilter,
    FilterTourism,
    SortTourism,
    FlightTourismItem,
    FullDetailModal2
  },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 90);
    return {
      sortedTourismDeals: [],
      filters: {
        from: "TLV",
        start_date: startDate,
        end_date: endDate,
        to: '',
        to_name: '',
        passengers: "",
        deal_length_min: 1,
        deal_length_max: 89,
        exact_dates: false,
        type: 'CHARTER'
      },
      pToId: '',
    }
  },
  // created() {
  //   this.filter_deal(0);
  // },
  computed: {
    ...mapState({
      loading: (state) => state.deal.loading,
      cheapestDealsData: (state) => state.deal.cheapestDealsAllData,
      deal_data: (state) => state.deal.dealData_receipts,
    }),
    routerName() {
      let result = '';
      if (this.user_type_logged_in == 'EndUser') {
        result = 'EndUserTourismProduct';
      } else {
        result = 'TourismProduct';
      }

      return result;
    },
  },
  methods: {
    ...mapActions("deal", {
      getCheapestDealsAll: "getCheapestDealsAll",
      getDeal: "getDeal"
    }),
    openFullDetailModal2(deal) {
      this.$refs.fullDetailModal2Ref.openModal(deal);

    },
    handleClickDeal({destination_id, type}) {
      this.filters.to = destination_id;
      this.filters.type = type;
      this.pToId = destination_id;
      this.filter_deal(0);
    },
    handleSetFilter(data) {
      this.filters.start_date = data.start_date;
      this.filters.end_date = data.end_date;
      this.filters.to = data.to;
      this.filters.to_name = data.to_name;
      this.filters.passengers = data.passengers;
    },

    async filter_deal(page_num=0) {
      if (this.filters.to) {
        let params = {
          to: this.filters.to,
          start_date: this.formatDate(this.filters.start_date),
          end_date: this.formatDate(this.filters.end_date),
          passengers: this.filters.passengers,
          budget: '',
          stars: '',
          exact_dates: false,
          deal_length_min: this.filters.deal_length_min,
          deal_length_max: this.filters.deal_length_max,
          type: this.filters.type,
          from: this.filters.from,  
          per_page: 40,
          page: page_num 
        }
  
        await this.getDeal({ filters: params });
  
        this.$router.push({ name: 'FlightAll', query: { 
          to: params.to, 
          start_date: params.start_date,
          end_date: params.end_date,
          passengers: params.passengers
        }});
  
        this.sortedTourismDeals = this.deal_data || [];
      } else {
        this.$router.push({ name: 'FlightHome'});
      }
      






      // let obj = this;
      // if (page_num == 0) {
      //   obj.sortedTourismDeals = [];
      // }
      // obj.tourismDes = obj.arrival.selected.label;

      // const start_date = obj.formatDate(obj.filters.dateRange.startDate);
      // const end_date = obj.formatDate(obj.filters.dateRange.endDate);
      // if (obj.filters.dateRange.night[0] === '') {
      //   obj.filters.exact_dates = true;
      // }
      // const defaultFilterData = {
      //   start_date: start_date,
      //   end_date: end_date,
      //   passengers: obj.passengers.selected.code,
      //   budget: obj.budget.selected.code,
      //   stars: obj.stars.selected.code,
      //   exact_dates: obj.filters.exact_dates,
      //   deal_length_min: obj.filters.dateRange.night[0],
      //   deal_length_max: obj.filters.dateRange.night[1]
      // };
      // let additionFilterData = {
      //   type: obj.deal.selected.code,
      //   from: obj.filters.from,  
      //   per_page: 40,
      //   page: page_num 
      // };

      //  params = {...defaultFilterData, ...additionFilterData}
      // if (obj.arrival.selected.code !== null) {
      //   params.to = obj.arrival.selected.code;
      //   obj.isStep2 = true;
      //   const id = this.arrival.selected.code;
      //   const type = this.deal.selected.code || "all";
      //   if (id != this.routerID || type != this.routerType) {
      //     this.$router.push({ name: 'Tourism', query: { id, type }});
      //   }
        
      //   if (this.deal.selected.code == '') {
      //     params.type = 'NOFSHON';
      //     await obj.getDeal({ filters: params });
      //     this.nofshonDeals = obj.deal_data || [];

      //     params.type = 'ORGANIZED_TOUR';
      //     await obj.getDeal({ filters: params });
      //     this.organizedDeals = obj.deal_data || [];

      //     if (this.nofshonDeals?.items?.length && this.organizedDeals?.items?.length) {
      //       this.$store.commit("deal/setIsShowTourismDealCarousel", true);
      //       // this.isShowTourismDealCarousel = true;
      //     } else {
      //       this.$store.commit("deal/setIsShowTourismDealCarousel", false);
      //       // this.isShowTourismDealCarousel = false;
      //       if (this.nofshonDeals?.items?.length) {
      //         this.deal.selected = {
      //           code: 'NOFSHON',
      //           label: this.$t("NOFSHON")
      //         }
      //         this.sortedTourismDeals = this.nofshonDeals;
      //       } else if (this.organizedDeals?.items?.length) {
      //         this.deal.selected = {
      //           code: 'ORGANIZED_TOUR',
      //           label: this.$t("Organized Tour")
      //         }
      //         this.sortedTourismDeals = this.organizedDeals
      //       } else {
      //         this.sortedTourismDeals = [];
      //       }
      //       this.createFilterData(this.sortedTourismDeals.items, page_num);
      //     }
      //   } else {
      //     if (this.isClickCheapestDeal && Object.keys(this.filterData).length) {
      //       params = {...this.filterData,  ...additionFilterData};
      //       params.to = obj.arrival.selected.code;
      //     } 
      //     await obj.getDeal({ filters: params });
      //     // this.isShowTourismDealCarousel = false;
      //     this.$store.commit("deal/setIsShowTourismDealCarousel", false);
      //     this.sortedTourismDeals = this.deal_data || [];
      //     this.createFilterData(this.sortedTourismDeals.items, page_num);
      //   }
      // } else {
      //   obj.isStep2 = false;
      //   obj.isViewAll = false;
      //   if (this.routerID) {
      //     this.$router.push({ name: 'Tourism'});
      //   }
      //   await obj.getCheapestDealsAll({ filters: params });
      //   if (!this.isClickCheapestDeal) {
      //     this.saveFilterData({ params: defaultFilterData });
      //   }
      // }
      // localStorage.setItem("passengers", obj.passengers.selected.code);
    },
    handleFilter() {
      this.filter_deal(0);
    }
  },
  mounted() {
    if(this.$route.query.to) {
      this.filters.to = this.$route.query.to;
    }
    this.filter_deal(0);
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/tourism.scss";
</style>